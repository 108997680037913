<template>
  <ModuleView>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                v-if="isSaveButtonVisible"
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <ButtonDelete v-if="isDeleteButtonVisible" sourceType="tag" @deleteRecord="deleteTag" />
              <ButtonClose route-name="commonTags_list" />
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-12">
                    <header v-if="showEditComponents" class="card card-body m-0 mb-5 p-3 edit-header">
                      <h6 class="m-0">
                        <span>{{ $t('tag.geneeaTag.id') }}:</span> {{ tag.id }}
                        <span>{{ $t('tag.geneeaTag.title') }}:</span> {{ tag.title }}
                        <span>{{ $t('tag.geneeaTag.type') }}:</span> {{ tag.type | capitalize}}
                        <span>{{ $t('tag.geneeaTag.generalInfo.createdAt') }}:</span> {{ tag.createdAt | prettyDateTime}}
                        <span>{{ $t('tag.geneeaTag.generalInfo.modifiedAt') }}:</span> {{ tag.modifiedAt | prettyDateTime}}
                      </h6>
                    </header>
                    <main>
                      <div class="row">
                        <!-- *** First col *** -->
                        <div class="col-lg-6 geenea-tag-first-col">
                          <!-- General information -->
                          <div class="geneea-tag-generalInfo">
                            <h3> {{ $t('tag.geneeaTag.generalInfo.title') }} </h3>
                            <Input
                              v-model="tag.title"
                              @blur="$v.tag.title.$touch()"
                              :error="$v.tag.title.$error"
                              id="tag_title"
                              :label="$t('tag.title')"
                              :disabled="isDisabled('title')"
                            />
                            <Input
                              v-model="tag.generatedSlug"
                              id="tag_generatedSlug"
                              :label="$t('tag.slug')"
                              :disabled="true"
                            />
                            <Input
                              v-model="tag.id"
                              :label="$t('tag.id')"
                              :disabled="isDisabled('id')"
                            />
                            <Input
                            v-model="tag.geneeaId"
                            :label="$t('tag.geneeaTag.geneeaId')"
                            :disabled="isDisabled('geneeaId')"
                            />
                            <Input
                              v-model="tag.type"
                              :label="$t('tag.geneeaTag.type')"
                              :disabled="isDisabled('type')"
                            />
                            <div class="title-text">
                              {{ $t('tag.description') }}
                            </div>
                            <RichTextEditor
                              v-model="tag.description"
                              :other-options="richTextEditorConfig"
                              id="geneeaTag_description"
                              class="mb-3"
                            />
                            <div class="title-text">
                              {{ $t('tag.image') }}
                            </div>
                            <ModuleFormPhotoBox
                              :image="tag.image"
                              :media-usage-type="tagMediaType"
                              hide-separator
                              @set-media="setTagImage"
                              @remove-media="removeTagImage"
                              class="mb-3"
                            />
                            <div class="form-group">
                              <label>{{ $t('article.related_articles') }}</label><br>
                              <button
                                class="btn btn-info"
                                data-test="article_btn_select_related_articles"
                                @click="switchRelatedArticleModal(true)"
                              >
                                {{ $t('buttons.select_articles') }}
                              </button>
                              <RelatedArticlesModal
                                v-if="relatedArticleModal"
                                @close="switchRelatedArticleModal(false)"
                                @set-data="addRelatedArticles"
                                multiple
                              />
                              <div class="m-t-10">
                                <RelatedArticleList
                                  :relatedArticlesProp="relatedArticles"
                                  @removeRelatedArticle="removeRelatedArticle"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- *** Second col *** -->
                        <div class="col-lg-6 geenea-tag-second-col">
                          <!--  event  -->
                          <div v-if="tag.type === TagTypes.EVENT" class="geneea-tag-additionalInfo">
                            <h3> {{ $t('tag.geneeaTag.event.title') }} </h3>
                            <div class="row">
                              <Datepicker
                                type="date"
                                v-model="tag.additionalInfo.event.startDate"
                                id="tag-additionalInfo-event-startDate"
                                :label="$t('tag.geneeaTag.event.startDate')"
                                :placeholder="$t('tag.geneeaTag.event.startDate')"
                                :max-date="convertToDate(tag.additionalInfo.event.endDate) || null"
                                class="col-lg-6"
                              />
                              <Datepicker
                                type="date"
                                v-model="tag.additionalInfo.event.endDate"
                                id="tag-additionalInfo-event-endDate"
                                :label="$t('tag.geneeaTag.event.endDate')"
                                :placeholder="$t('tag.geneeaTag.event.endDate')"
                                :min-date="convertToDate(tag.additionalInfo.event.startDate) || null"
                                class="col-lg-6"
                              />
                            </div>
                            <AddressInput @newAddress="setNewAddress" tagType="event" :tag="tag"/>
                          </div>
                          <!--  location  -->
                          <div v-if="tag.type === TagTypes.LOCATION" class="geneea-tag-additionalInfo">
                            <h3> {{ $t('tag.geneeaTag.location.title') }} </h3>
                            <AddressInput @newAddress="setNewAddress" :tagType="TagTypes.LOCATION" :tag="tag"/>
                            <Input
                              v-model.number="tag.additionalInfo.location.population"
                              class="mt-2"
                              :label="$t('tag.geneeaTag.location.population')"
                              type="number"
                              :min="1"
                              :max="2147483000"
                            />
                          </div>
                          <!--  organisation  -->
                          <div v-if="tag.type === TagTypes.ORGANISATION" class="geneea-tag-additionalInfo">
                            <h3> {{ $t('tag.geneeaTag.organisation.title') }} </h3>
                            <Input
                              v-model="tag.additionalInfo.organisation.alternateName"
                              :label="$t('tag.geneeaTag.organisation.alternateName')"
                            />
                            <AddressInput @newAddress="setNewAddress" tagType="organisation" :tag="tag"/>
                            <div class="title-text">
                              {{ $t('tag.geneeaTag.organisation.logo') }}
                            </div>
                            <ModuleFormPhotoBox
                              :image="tag.additionalInfo.organisation.logoImage"
                              :media-usage-type="tagMediaType"
                              hide-separator
                              @set-media="setLogoImg"
                              @remove-media="removeLogoImage"
                              class="mb-3"
                            />
                          </div>
                          <!--  Person  -->
                          <div v-if="tag.type === TagTypes.PERSON" class="geneea-tag-additionalInfo">
                            <h3> {{ $t('tag.geneeaTag.person.title') }} </h3>
                            <Input
                              v-model="tag.additionalInfo.person.givenName"
                              :label="$t('tag.geneeaTag.person.givenName')"
                            />
                            <Input
                              v-model="tag.additionalInfo.person.familyName"
                              :label="$t('tag.geneeaTag.person.familyName')"
                            />
                            <Input
                              v-model="tag.additionalInfo.person.hasOccupation"
                              :label="$t('tag.geneeaTag.person.hasOccupation')"
                            />
                            <div class="row">
                              <Datepicker
                                type="date"
                                v-model="tag.additionalInfo.person.birthDate"
                                id="tag-additionalInfo-person-birthDate"
                                :label="$t('tag.geneeaTag.person.birthDate')"
                                :placeholder="$t('tag.geneeaTag.person.birthDate')"
                                :max-date="convertToDate(tag.additionalInfo.person.deathDate) || null"
                                class="col-lg-4"
                              />
                              <Datepicker
                                type="date"
                                v-model="tag.additionalInfo.person.deathDate"
                                id="tag-additionalInfo-person-deathDate"
                                :label="$t('tag.geneeaTag.person.deathDate')"
                                :placeholder="$t('tag.geneeaTag.person.deathDate')"
                                :min-date="convertToDate(tag.additionalInfo.person.birthDate) || null"
                                class="col-lg-4"
                              />
                            </div>
                            <Input
                              v-model="tag.additionalInfo.person.birthPlace"
                              :label="$t('tag.geneeaTag.person.birthPlace')"
                            />
                            <Input
                              v-model="tag.additionalInfo.person.deathPlace"
                              :label="$t('tag.geneeaTag.person.deathPlace')"
                            />
                          </div>
                          <!--  Product  -->
                          <div v-if="tag.type === TagTypes.PRODUCT" class="geneea-tag-additionalInfo">
                            <h3> {{ $t('tag.geneeaTag.product.title') }} </h3>
                            <Input
                              v-model="tag.additionalInfo.product.manufacturer"
                              :label="$t('tag.geneeaTag.product.manufacturer')"
                            />
                            <Input
                              v-model="tag.additionalInfo.product.model"
                              :label="$t('tag.geneeaTag.product.model')"
                            />
                          </div>
                          <!--  General  -->
                          <div v-if="tag.type === TagTypes.GENERAL" class="geneea-tag-additionalInfo">
                            <h3> {{ $t('tag.geneeaTag.general.title') }} </h3>
                          </div>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </ModuleView>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Input from '@/components/form/inputs/Input'
import AddressInput from '@/components/address/AddressInput'
import Datepicker from '@/components/form/Datepicker'
import ModuleView from '@/components/ModuleView'
import RelatedArticleList from '@/components/article/ArticleRelatedList'
import RelatedArticlesModal from '@/components/article/ArticleRelatedModal'
import ButtonDelete from '@/components/shared/ButtonDelete'
import ButtonClose from '@/components/shared/ButtonClose'
import NotifyService from '@/services/NotifyService'
import PermissionService from '@/services/PermissionService'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import GeneeaTagModel from '@/model/GeneeaTagModel'
import { TagTypes } from '@/model/ValueObject/TagTypes'
import { MEDIA_USAGE_TYPE_GENEEA_TAG } from '@/model/ValueObject/MediaUsageTypes'
import { capitalize } from '@/filters'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import TinyMceConfig from '@/services/tinymce/TinyMceConfig'

const validOrEmptyGeo = value => {
  if (value === '' || value === null) return true
  const geoPattern = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/
  return geoPattern.test(value)
}

export default {
  name: 'GeneeaTagNewView',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      tag: this._.cloneDeep(GeneeaTagModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_TAG_PERMISSIONS,
      showEditComponents: false,
      richTextEditorConfig: TinyMceConfig.getConfig(),
      relatedArticleModal: false,
      relatedArticles: [],
      relatedArticlesIds: [],
      tagMediaType: MEDIA_USAGE_TYPE_GENEEA_TAG,
      disabledInputs: ['id', 'title', 'slug', 'geneeaId', 'type']
    }
  },
  components: {
    RichTextEditor,
    ModuleView,
    Input,
    ButtonDelete,
    ButtonClose,
    Datepicker,
    RelatedArticlesModal,
    RelatedArticleList,
    AddressInput,
    ModuleFormPhotoBox
  },
  validations: {
    tag: {
      title: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(60)
      },
      additionalInfo: {
        location: {
          geo: {
            validOrEmptyGeo
          }
        }
      }
    }
  },
  computed: {
    TagTypes () {
      return TagTypes
    },
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isDeleteButtonVisible () {
      return this.tag?.id && this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    },
    detail () {
      return this.$store.getters['tag/geneeaDetail']
    }
  },
  methods: {
    capitalize,
    convertToDate (dateString) {
      return dateString ? new Date(dateString) : null
    },
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    switchRelatedArticleModal (showModal) {
      this.relatedArticleModal = showModal
    },
    setNewAddress (data) {
      this.tag.additionalInfo[data.type] = { ...this.tag.additionalInfo[data.type], ...data.address }
    },
    addRelatedArticles (newRelatedArticles) {
      const existingIds = new Set(this.relatedArticles.map(article => article.id))
      const uniqueNewArticles = newRelatedArticles.filter(article => !existingIds.has(article.id))
      this.relatedArticles = [...this.relatedArticles, ...uniqueNewArticles]
      this.relatedArticlesIds = this.relatedArticles.map(article => article.id)
    },
    removeRelatedArticle (article) {
      if (!article || !article.id) {
        console.error('Article is not valid')
        return
      }
      this.relatedArticles = this.relatedArticles.filter(item => item.id !== article.id)
      this.relatedArticlesIds = this.relatedArticlesIds.filter(item => item !== article.id)
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('tag/create', this.tag)
        .then(() => {
          if (this.$store.getters['tag/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
            this.tag = this.$store.getters['tag/geneeaDetail']
            // this.$router.push('/tag/' + this.tag.id + '/edit')
            this.$router.push('/commonTags')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['tag/error'])
          }
        })
        .catch(error => console.log(error))
    },
    deleteTag () {
      this.$store.dispatch('tag/deleteGeneeaRecord', this.tag)
        .then(() => {
          if (this.$store.getters['tag/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/commonTags')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['tag/error'])
          }
        })
        .catch(error => console.log(error))
    },
    setTagImage (image) {
      this.tag.image = image[0]
      this.tag.imageId = image[0].id
    },
    removeTagImage (image) {
      this.tag.image = {}
      this.tag.imageId = null
    },
    setLogoImg (image) {
      this.tag.additionalInfo.organisation.logoImage = image[0]
      this.tag.additionalInfo.organisation.logoImageId = image[0].id
    },
    removeLogoImage () {
      this.tag.additionalInfo.organisation.logoImage = {}
      this.tag.additionalInfo.organisation.logoImageId = null
    },
    isDisabled (input) {
      return this.disabledInputs.includes(input)
    }
  },
  watch: {
    relatedArticles: {
      deep: true,
      handler (newVal) {
        this.tag.relatedArticles = newVal
      }
    },
    relatedArticlesIds: {
      deep: true,
      handler (newVal) {
        this.tag.relatedArticlesIds = newVal
      }
    }
  }
}
</script>

<style lang="scss" scoped>

h3 {
  color: #bbb;
  font-weight: 100;
}

.edit-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  span {
    color: #bbb;
    font-weight: 100;
    margin-left: 3rem;
  }
  span:nth-child(1) {
    margin-left: 1rem;
  }
}

</style>

<template>
  <div id="map"></div>
</template>

<script>
import appConfig from '@/config'
import L from 'leaflet'
import gpsIcon from '@/assets/img/svg/gps.svg'
import 'leaflet/dist/leaflet.css'

export default {
  name: 'MapComponent',
  props: {
    geo: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      API_KEY: appConfig.mapyCZ.xApiKey(),
      API_URL: appConfig.mapyCZ.apiUrl(),
      map: null,
      marker: null
    }
  },
  computed: {
    createGeoPos () {
      const regex = /^-?\d+(\.\d+)?, ?-?\d+(\.\d+)?$/
      const str = this.geo
      if (regex.test(str)) {
        return str.split(',').map(num => parseFloat(num.trim()))
      }
      this.$emit('errMsg', { geoErrMsg: 'Invalid GPS position' })
      return null
    }
  },
  methods: {
    initMap () {
      this.map = L.map('map').setView(this.createGeoPos, 16)

      L.tileLayer(`${this.API_URL}/maptiles/basic/256/{z}/{x}/{y}?apikey=${this.API_KEY}`, {
        minZoom: 0,
        maxZoom: 19,
        attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>'
      }).addTo(this.map)

      this.addMarker(this.createGeoPos)

      const LogoControl = L.Control.extend({
        options: {
          position: 'bottomleft'
        },
        onAdd: function () {
          const container = L.DomUtil.create('div')
          const link = L.DomUtil.create('a', '', container)
          link.setAttribute('href', 'http://mapy.cz/')
          link.setAttribute('target', '_blank')
          link.innerHTML = '<img src="https://api.mapy.cz/img/api/logo.svg" />'
          L.DomEvent.disableClickPropagation(link)
          return container
        }
      })
      new LogoControl().addTo(this.map)
    },
    updateMapPosition () {
      if (this.createGeoPos) {
        this.map.setView(this.createGeoPos, 16)
        this.addMarker(this.createGeoPos)
      }
    },
    addMarker (position) {
      if (this.marker) {
        this.map.removeLayer(this.marker)
      }
      const customIcon = L.icon({
        iconUrl: gpsIcon,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        popupAnchor: [0, -30]
      })

      this.marker = L.marker(position, { icon: customIcon }).addTo(this.map)
    }
  },
  watch: {
    geo: {
      immediate: true,
      handler () {
        if (this.map && this.createGeoPos) {
          this.updateMapPosition()
        }
      }
    }
  },
  mounted () {
    this.initMap()
  }
}
</script>

<style scoped lang="scss">
#map {
  width: 100%;
  height: 100%;
  min-height: 400px;
}
</style>

<template>
  <div class="address-input">
    <!-- Address -->
    <div class="autocomplete-wrapper">
      <Input
        v-model="address.address"
        :label="$t('address.address')"
        @input="handleAddressInput"
        @blur="emitAddress"
        @change="emitAddress"
      />
      <div class="autocomplete-results" v-if="showAddressSuggestions">
        <ul>
          <li
            v-for="(item, index) in sortedAddressSuggestions"
            :key="`${item.id}_sortedAddressSuggestions_${index}`"
            :class="{'item-from-address-input':item.isFromInput}"
            @mousedown.prevent="selectAddressSuggestion(item)"
          >
            <span v-if="item.isFromInput">"{{ item.name }}"</span>
            <span v-else>{{ item.name }}</span>
            <span v-if="item.locationDetails">, ({{ item.locationDetails }})</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- GPS -->
    <div>
      <small class="form-control-feedback text-danger float-right" v-if="errMsg.geoErrMsg"><i class="fa fa-exclamation-triangle" />  {{ errMsg.geoErrMsg }}</small>
      <Input
        v-model="address.geo"
        :label="$t('address.geo')"
        :error="!!errMsg.geoErrMsg"
        @input="handleGeoChange"
        @blur="handleGeoChange"
      />
    </div>
    <!-- District -->
    <Input
      v-model="address.district"
      :label="$t('address.districtId')"
      @blur="emitAddress"
      @input="clearAddressOnFieldChange('district')"
    />
    <!-- Region -->
    <Input
      v-model="address.region"
      :label="$t('address.regionId')"
      @blur="emitAddress"
      @input="clearAddressOnFieldChange('region')"
    />
    <!-- Country -->
    <Input
      v-model="address.country"
      :label="$t('address.countryId')"
      @blur="emitAddress"
      @input="clearAddressOnFieldChange('country')"
    />
    <!-- Map -->
    <MapComponent
      v-if="isValidGeo(address.geo)"
      :geo="address.geo"
      @errMsg="geoErrMsg"
    />
  </div>
</template>

<script>
import appConfig from '@/config'
import Input from '@/components/form/inputs/Input'
import MapComponent from '@/components/address/MapComponent'

export default {
  name: 'AddressInput',
  components: {
    Input,
    MapComponent
  },
  data () {
    return {
      API_KEY: appConfig.mapyCZ.xApiKey(),
      API_URL: appConfig.mapyCZ.apiUrl(),
      address: {
        geo: '',
        address: '',
        district: '',
        region: '',
        country: '',
        population: ''
      },
      errMsg: {
        geoErrMsg: null
      },
      addressSuggestions: [],
      countrySuggestions: [],
      showAddressSuggestions: false,
      showCountrySuggestions: false,
      showSuggestions: false
    }
  },
  props: {
    tag: {
      type: Object,
      default: () => ({ additionalInfo: {} })
    },
    tagType: {
      type: String,
      default: ''
    }
  },
  computed: {
    sortedAddressSuggestions () {
      const currentInput = {
        name: this.address.address,
        locationDetails: '',
        country: '',
        location: '',
        region: '',
        isFromInput: true,
        data: {}
      }
      return [
        currentInput,
        ...this.addressSuggestions.filter(item => item && item.name !== this.address.address)
      ]
    }
  },
  methods: {
    isValidGeo (geo) {
      const geoPattern = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/
      return geoPattern.test(geo)
    },
    async searchAddress () {
      if (this.address.address.length < 3) {
        this.addressSuggestions = []
        this.showAddressSuggestions = false
        return
      }
      try {
        const response = await fetch(`${this.API_URL}/suggest?lang=cs&limit=5&type=regional.address&apikey=${this.API_KEY}&query=${this.address.address}`)
        const data = await response.json()
        this.addressSuggestions = data.items.map(item => {
          const regionalCountry = item.regionalStructure.find(i => i.type === 'regional.country') ?? {}
          const regionalRegions = item.regionalStructure.filter(i => i.type === 'regional.region') ?? []
          let location = {}
          let region = {}

          if (regionalRegions.length > 0) {
            location = regionalRegions.find(r => r.name.toLowerCase().includes('okres'))
            region = regionalRegions.find(r => r.name.toLowerCase().includes('kraj'))
          }

          return {
            name: item?.name ?? '',
            locationDetails: item?.location ?? '',
            country: regionalCountry?.name ?? '',
            location: location?.name ?? '',
            region: region?.name ?? '',
            data: item ?? {}
          }
        })
        this.showAddressSuggestions = true
      } catch (error) {
        console.error('Error fetching suggestions:', error)
        this.addressSuggestions = []
        this.showAddressSuggestions = false
      }
    },
    handleAddressInput () {
      if (!this.address.address || this.address.address.length < 3) {
        if (this.address.address.length === 0) this.clearAddressFields()
        this.showAddressSuggestions = false
      } else {
        this.searchAddress()
      }
    },
    updateAddress (item, type) {
      this.address.geo = item.data && item.data.position ? `${item.data.position.lat},${item.data.position.lon}` : ''
      this.address.country = type === 'address' ? (item.country || '') : (item.value || '')
      this.address.address = type === 'address' ? `${item.name || ''}${item.locationDetails ? ', ' + item.locationDetails : ''}` : ''
      this.address.district = type === 'address' ? (item.location || '') : ''
      this.address.region = type === 'address' ? (item.region || '') : ''
      this.showAddressSuggestions = false
      this.showCountrySuggestions = false
      this.emitAddress()
    },
    closeSuggestions () {
      this.showAddressSuggestions = false
    },
    selectAddressSuggestion (item) {
      this.updateAddress(item, 'address')
    },
    emitAddress () {
      this.$emit('newAddress', {
        type: this.tagType,
        address: this.address
      })
      this.closeSuggestions()
    },
    clearAddressFields () {
      this.address.geo = ''
      this.address.address = ''
      this.address.district = ''
      this.address.region = ''
      this.address.country = ''
      this.errMsg.geoErrMsg = null
      this.emitAddress()
    },
    clearAddressOnFieldChange (field) {
      if (this.address[field] === '') {
        this.clearAddressFields()
      } else {
        this.emitAddress()
      }
    },
    geoErrMsg (msg) {
      this.errMsg.geoErrMsg = msg.geoErrMsg
    },
    async handleGeoChange () {
      if (!this.address.geo) {
        this.clearAddressFields()
      } else if (this.isValidGeo(this.address.geo)) {
        this.errMsg.geoErrMsg = null
        const [lat, lon] = this.address.geo.split(',')
        try {
          const response = await fetch(`${this.API_URL}/rgeocode/?lon=${lon}&lat=${lat}&apikey=${this.API_KEY}`)
          const data = await response.json()
          if (data?.items?.length > 0) {
            const responseFirstItem = data.items[0]
            const regionalCountry = responseFirstItem.regionalStructure?.find(i => i.type === 'regional.country')?.name || ''
            const regionalRegions = responseFirstItem.regionalStructure?.filter(i => i.type === 'regional.region') || []

            let location = {}
            let region = {}

            if (regionalRegions.length > 0) {
              location = regionalRegions.find(r => r.name.toLowerCase().includes('okres')) || {}
              region = regionalRegions.find(r => r.name.toLowerCase().includes('kraj')) || {}
            }

            this.address.country = regionalCountry
            this.address.region = region?.name || ''
            this.address.address = responseFirstItem.name + (responseFirstItem.location ? ', ' + responseFirstItem.location : '') || ''
            this.address.district = location?.name || ''
            this.emitAddress()
          } else {
            this.errMsg.geoErrMsg = this.$t('notification.no_results_found_for_this_gps')
          }
        } catch (error) {
          this.errMsg.geoErrMsg = this.$t('notification.error_fetching_address_from_gps')
        }
      } else {
        this.errMsg.geoErrMsg = `${this.$t('notification.invalid_gps_coordinates')} - ${this.$t('notification.vlm_valid_GPS_format')}`
      }
    }
  },
  watch: {
    tagTypeInfo: {
      deep: true,
      handler (newVal) {
        if (newVal) {
          const source = newVal
          Object.keys(this.address).forEach(key => {
            if (key in source) {
              this.address[key] = source[key]
            }
          })
        }
      }
    }
  },
  mounted () {
    if (this.tag && this.tagType && this.tag.additionalInfo[this.tagType]) {
      const source = this.tag.additionalInfo[this.tagType]
      Object.keys(this.address).forEach(key => {
        if (key in source) {
          this.address[key] = source[key]
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.autocomplete-wrapper {
  position: relative;
}

.autocomplete-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  z-index: 1000;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 8px 10px;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }
  }

  .item-from-address-input {
    font-style: italic;
    background: #f8f8f8;
  }
}
</style>
